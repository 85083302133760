/* eslint-disable import/no-default-export */
import * as React from 'react'
import { useState } from 'react'

import { Link, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import type { IGatsbyImageData } from 'gatsby-plugin-image'

import {
  Content,
  WorkList,
  WorkTitle,
  DynamicImage,
} from '../../components/Layout/Components'
import { Layout } from '../../components/Layout/Layout'
import {
  MobileContent,
  MobileList,
  WorkTitleMobile,
  MobileNomeProjecto,
} from '../../components/Layout/MobileComponents'

type Post = {
  frontmatter: {
    title: string
    gallery: any
    galleryalt: string
  }
  fields: {
    slug: string
  }
}

const keywords = 'carina martins, projectos, trabalhos, fotografia'
const description = 'projectos de fotografia de carina martins'

const Index = ({
  data,
  location,
}: {
  location: Location
  data: any
}): JSX.Element => {
  const siteTitle = data.site.siteMetadata?.title || 'Title'
  const posts = data.allMarkdownRemark.nodes
  const [image, setImage] = useState(getImage(posts[0].frontmatter.gallery))
  const [imageAlt, setImageAlt] = useState(posts[0].frontmatter.galleryalt)

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle} alternate="../">
        {''}
      </Layout>
    )
  }

  return (
    <Layout
      location={location}
      title={siteTitle}
      lang="pt"
      alternate="../"
      description={description}
      keywords={keywords}
    >
      <Content>
        <WorkList>
          <ol style={{ listStyle: 'none', maxWidth: '95%' }}>
            {posts.map((post: Post) => {
              const title = post.frontmatter.title || post.fields.slug
              return (
                <WorkTitle key={post.fields.slug} itemScope>
                  <Link
                    to={post.fields.slug}
                    itemProp="url"
                    onMouseEnter={() => {
                      setImage(getImage(post.frontmatter.gallery))
                      setImageAlt(post.frontmatter.galleryalt)
                      return false
                    }}
                  >
                    {title}
                  </Link>
                </WorkTitle>
              )
            })}
          </ol>
        </WorkList>
        <DynamicImage>
          <GatsbyImage
            image={image as IGatsbyImageData}
            alt={imageAlt}
            loading="eager"
            objectFit="contain"
            objectPosition="50% 50%"
            className="mainMenuImage"
          />
        </DynamicImage>
      </Content>
      <MobileContent>
        <MobileList>
          {posts.map((post: Post) => {
            const mimageAlt = post.frontmatter.galleryalt
            const mtitle = post.frontmatter.title || post.fields.slug
            const mimage = getImage(post.frontmatter.gallery)
            return (
              <WorkTitleMobile key={post.fields.slug} itemScope>
                <Link
                  to={post.fields.slug}
                  itemProp="url"
                  onMouseEnter={() => {
                    setImage(getImage(post.frontmatter.gallery))
                    setImageAlt(post.frontmatter.galleryalt)
                    return false
                  }}
                >
                  <GatsbyImage
                    image={mimage as IGatsbyImageData}
                    alt={mimageAlt}
                    loading="eager"
                    objectFit="fill"
                    objectPosition="50% 50%"
                    className="mainMenuImageMobile"
                  />
                  <MobileNomeProjecto>{mtitle}</MobileNomeProjecto>
                </Link>
              </WorkTitleMobile>
            )
          })}
        </MobileList>
      </MobileContent>
    </Layout>
  )
}

export default Index

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: frontmatter___order, order: DESC }
      filter: { frontmatter: { lang: { eq: "pt" } } }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          title
          description
          date
          gallery {
            childImageSharp {
              gatsbyImageData
            }
          }
          galleryalt
          lang
          order
        }
      }
    }
  }
`
